import React, { useState } from "react";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import FileBase64 from "react-file-base64";

//form sets init state
//update creates array of updated values

export default function LeadsForm(props) {
  return (
    <>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Lead Information</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Subject"
                name="subject"
                value={props.data.subject}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <TextField
                label="Reference Number"
                name="account_number"
                value={props.data.account_number}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <TextField
                label="Company Name"
                name="client_name"
                value={props.data.client_name}
                fullWidth={true}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
              ></TextField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-4">
              <InputLabel></InputLabel>
              <TextField
                label="Contact Email"
                value={props.data.client_email}
                name="client_email"
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel></InputLabel>
              <TextField
                label="Contact Phone"
                value={props.data.client_tel}
                name="client_tel"
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Allocate To</InputLabel>
              <Select
                fullWidth
                name="allocated_uuid"
                value={props.data.allocated_uuid}
                onChange={(e) => {
                  const username = props.users.find((user) => {
                    return user.uuid == e.target.value;
                  });
                  props.handleAllocateChange( e.target.value, username.name )
                  }
                }
              >
                {props.users &&
                  props.users.map((item, key) => {
                    return (
                      <MenuItem key={item.uuid} value={item.uuid}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-4">
              <InputLabel>Associated Business Unit</InputLabel>
              <Select
                fullWidth
                name="business_unit"
                value={props.data.business_unit}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
              >
                {props.businesses &&
                  props.businesses.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>

            <div className="form-group col-lg-4">
              <InputLabel></InputLabel>
              <TextField
                label="Lead Source"
                value={props.data.lead_source}
                name="lead_source"
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>

            <div className="form-group col-lg-4">
              <InputLabel></InputLabel>
              <TextField
                label="Date Created"
                value={props.data.created_at}
                name="created_at"
                disabled
                fullWidth={true}
              ></TextField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="Lead Notes"
                value={props.data.lead_notes}
                name="lead_notes"
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Lead Qualification</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <h5 style={{ margin: 0 }}>Credit</h5>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Credit Check Score"
                name="credit_check_score"
                type="number"
                value={props.data.credit_check_score}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="Credit Check Notes"
                name="credit_check_notes"
                multiline
                rows={3}
                value={props.data.credit_check_notes}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <FileBase64
                multiple={true}
                onDone={(e) => props.handleUpload(e)}
              />
            </div>
          </div>

          <div classname="form-row">
              {Array.isArray( props.data.credit_documents ) && props.data.credit_documents.map((item, key) => {
                  return (
                    <>
                      <Button
                        varient="contained"
                        style={{ "background": "blue", "color": "white", "margin-left": "10" }}
                        onClick={() => props.handleDownload(item.id)}
                      >
                        { "Credit Document " + item.created_at }
                      </Button>
                    </>
                  );
                })
              }
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <h5 style={{ margin: 0 }}>Budget</h5>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-3">
              <TextField
                label="Current Spend on Solution"
                name="current_spend_solution"
                type="number"
                value={props.data.current_spend_solution}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-3">
              <TextField
                label="Budget"
                name="budget"
                type="number"
                value={props.data.budget}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>

            <div className="form-group col-lg-3">
              <TextField
                label="Decision Maker"
                name="decision_maker"
                value={props.data.decision_maker}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>

            <div className="form-group col-lg-3">
              <InputLabel>Delivery Date</InputLabel>
              <TextField
                name="delivery_date"
                type="date"
                value={props.data.delivery_date}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Customer Buying Process"
                name="buying_process"
                value={props.data.buying_process}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
