import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import LeadsView from "./pages/view";
import Leads from "./pages/index";
import LeadsCreate from "./pages/create";
import LeadsEdit from "./pages/edit";


export default function Root(props) {

  let context = { ...props }
  let history = useHistory();


  return (
    <AuthContext.Provider
      value={{
        ...props
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {props.auth.access.leads["A"] && props.auth.access.leads["R"] &&
                      <Route path="/leads/index" exact component={(route) => <Leads route={route} history={history} context={context} />} />}

                    {props.auth.access.leads["A"] && props.auth.access.leads["R"] &&
                      <Route
                        path="/leads/view/:id"
                        exact
                        component={(route) => <LeadsView route={route} history={history} context={context} />}
                      />}
                    {props.auth.access.leads["A"] && props.auth.access.leads["U"] &&

                      <Route
                        path="/leads/edit/:id"
                        exact
                        component={(route) => <LeadsEdit route={route} context={context} history={history} />}
                      />
                    }
                    {props.auth.access.leads["A"] && props.auth.access.leads["C"] &&

                      <Route
                        path="/leads/create"
                        exact
                        component={(route) => <LeadsCreate route={route} history={history} context={context} />}
                      />
                    }
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}