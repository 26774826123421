import React, { useState, useEffect } from "react";

import { AppHeaderComponent, LeadsApi, CodesApi, PersonnelApi } from "@unity/components";
import LeadsForm from "../modules/leads/LeadsForm";
import ApiLoader from "../common/ApiLoader";
import { useHistory } from "react-router-dom";

export default function LeadsCreate({ route, context }) {
  const [data, setData] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState({ status: false });
  const [businesses, setBusinesses] = useState([]);
  let history = useHistory();

  const handleSave = async () => {
    setLoading({ status: true, data: "Loading please wait..." });
    var res = await LeadsApi.createLeads(data);
    if (res.message && res.message.status == 460) {
      setLoading({ status: false, data: "" });
      alert("Company Name and Contact phone are Required");
    } else {
      setLoading({ status: true, data: "Save Success please wait..." });
      setTimeout(() => {
        history.push("/leads/index");
      }, 4000);
    }
  };

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleBusinessChange = async (val) => {
    const newData = { ...data };
    newData["business_unit"] = val;
    setData(newData);
    await getProductCategories(val);
  };

  const handleAllocatedChange = async (uuid, name) => {
    const newData = { ...data };
    newData["allocated_uuid"] = uuid;
    newData["allocated_name"] = name;
    setData(newData);
  }

  const getBusinesses = async () => {
    const res = await CodesApi.getBusinessUnits();
    console.log("business", res.data);
    setBusinesses(res.data);
  };

  const handleUpload = (e) => {
    const newData = { ...data };
    newData["credit_documents"] = e;
    setData(newData);
  };

  const getUsers = async () => {
    const res = await PersonnelApi.getPersonnel();
    setUsers(res.data);
  }

  useEffect(() => {
    getBusinesses();
    getUsers();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="leads"
        />
        <ApiLoader status={loading.status} data={loading.data} />
        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <LeadsForm
            data={data}
            users={users}
            handleChange={handleChange}
            handleUpload={handleUpload}
            handleBusinessChange={handleBusinessChange}
            businesses={businesses}
            handleAllocatedChange={handleAllocatedChange}
            type="edit"
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
