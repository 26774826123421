import React, { useEffect, useState } from "react";
import {
  AppHeaderComponent,
  AppButtonComponent,
  LeadsApi,
  CodesApi,
  PersonnelApi,
} from "@unity/components";
import LeadsForm from "../modules/leads/LeadsForm";
import Loading from "../common/Loading";
import { Link, useHistory } from "react-router-dom";
import ApiLoader from "../common/ApiLoader";
import { IsModuleEnabled } from "../services/Permissions";
import LeadsFormPopup from "../modules/leads/LeadsFormPopup";

export default function LeadsEdit({ route, context }) {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [open, setOpen] = useState({ state: false, fn: false });
  const [users, setUsers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [productCategories, setProductCategories] = useState([]);

  let history = useHistory();

  const getLeadSingle = async () => {
    const res = await LeadsApi.showLeads(route.match.params.id);
    setData(res.data);
    await getProductCategories(res.data.business_unit);
  };

  const getProductCategories = async (id) => {
    const res = await CodesApi.subOptionsIndexForOption(id);
    setProductCategories(res.data);
  };

  const handleBusinessChange = async (val) => {
    const newData = { ...data };
    newData["business_unit"] = val;
    setData(newData);
    await getProductCategories(val);
  };

  const handleAllocatedChange = async (uuid, name) => {
    const newData = { ...data };
    newData["allocated_uuid"] = uuid;
    newData["allocated_name"] = name;
    setData(newData);
  }

  const getBusinesses = async () => {
    const res = await CodesApi.getBusinessUnits();
    setBusinesses(res.data);
  };

  const getUsers = async () => {
    const res = await PersonnelApi.getPersonnel();
    setUsers(res.data);
  }

  const handleConvertLost = () => {
    setOpen({
      state: true,
      fn: LeadsApi.markLeadLost,
      title: "Close Lead as Lost?",
      text: "Are you sure you want to convert lead to the status of lost?",
      id: route.match.params.id,
    });
  };

  const handleConvertSales = () => {
    setOpen({
      state: true,
      fn: LeadsApi.markLeadWon,
      title: "Close Lead as Won?",
      text: "Are you sure you want to convert lead to the status of won?",
      id: route.match.params.id,
    });
  };

  const handleSave = async () => {
    setLoading({ status: true, data: "Loading please wait..." });
    await LeadsApi.updateLeads(route.match.params.id, data);
    setLoading({ status: true, data: "Save Success please wait..." });
    setTimeout(() => {
      history.push("/leads/index");
    }, 4000);
  };

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleUpload = (e) => {
    const newData = { ...data };
    newData["credit_documents"] = e;
    setData(newData);
  };

  const handleDownload = async (id) => {
    var downloadRes = await LeadsApi.getFiles(id);
    window.open(downloadRes.data, "_blank");
  };

  useEffect(() => {
    getLeadSingle();
    getBusinesses();
    getUsers();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="leads"
        />
        <ApiLoader status={loading.status} data={loading.data} />

        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <LeadsFormPopup open={open} setOpen={setOpen} />
          <AppButtonComponent
            onClick={() => handleConvertLost()}
            text="close as lost"
            btnIcon={"close"}
            style={{ background: "red", color: "white", marginLeft: 20 }}
          />
          {IsModuleEnabled("activities") && (
            <Link
              to={{
                pathname: `/activities/communication/lead/8/all/index`,
                state: { filter: "Lead" },
              }}
            >
              <AppButtonComponent
                text="view activities"
                btnIcon={"visibility"}
                style={{
                  background: context.theme.sidebar.background,
                  color: "white",
                  marginLeft: 20,
                }}
              />
            </Link>
          )}
          <LeadsForm
            data={data}
            businesses={businesses}
            users={users}
            productCategories={productCategories}
            handleChange={handleChange}
            handleUpload={handleUpload}
            handleDownload={handleDownload}
            handleBusinessChange={handleBusinessChange}
            handleAllocateChange={handleAllocatedChange}
            type="edit"
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
