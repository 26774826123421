import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import AppButton from "../common/AppButton";
import Loading from "../common/Loading";
import {
  LeadsApi,
  AppHeaderComponent,
  AppButtonComponent,
} from "@unity/components";
import FilterTable from "../common/FilterTable";
import ComponentTabs from "../common/Tabs";

export default function Leads({ route, context }) {
  const [data, setData] = useState(false);

  const methods = {
    0: LeadsApi.getLeadsIndexOpen(),
    1: LeadsApi.getLeadsIndexByClosed(),
    2: LeadsApi.getLeadsIndexByConverted(),
    3: LeadsApi.getLeadsRestoreIndex(),
  };

  const columns = [
    { name: "company_name", label: "Company Name" },
    { name: "contact_email", label: "Contact Email" },
    { name: "contact_phone", label: "Contact Phone" },
    { name: "allocated_name", label: "Allocated To" },
    {
      name: "subject",
      label: "Lead Subject",
      options: {
        customBodyRenderLite: (rowIndex) => {
          if (data[rowIndex].subject) {
            let string = data[rowIndex].subject;
            if (string.length <= 20) return string;
            else {
              return string.slice(0, 20) + "...";
            }
          }
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = true;

          return (
            <>
              <Link
                to={{
                  pathname: `/leads/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  text="View"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>

              {edit && (
                <Link
                  to={{
                    pathname: `/leads/edit/${data[dataIndex].id}`,
                  }}
                >
                  <AppButton
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </Link>
              )}

              <Link
                to={{
                  pathname: "/sales-op/create",
                  state: { id: data[dataIndex].id, comingFromleads: true },
                }}
              >
                <AppButton
                  text="Convert To Opportunity"
                  btnIcon="add"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginLeft: 10,
                  }}
                />
              </Link>
            </>
          );
        },
      },
    },
  ];

  const getLeadsIndex = async () => {
    const res = await LeadsApi.getLeadsIndexOpen();
    setData(res.data);
  };

  useEffect(() => {
    getLeadsIndex();
  }, []);

  var select = "multiple";

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: select,
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(async (item, key) => {
        await LeadsApi.deleteLeads(data[item.dataIndex].id);
      });
    },
  };

  const [tabValue, setTabValue] = useState(0);

  const tabs = [
    { icon: "check", label: "Open Leads" },
    { icon: "close", label: "Lost Leads" },
    { icon: "refresh", label: "Converted Leads" },
    { icon: "delete", label: "Deleted Leads" },
    { icon: "settings", label: "Module Settings" },
  ];

  const handleChange = async (event, newValue) => {
    setTabValue(newValue);
    if (newValue <= 3) {
      setData(false);
      const res = await methods[newValue];
      setData(res.data);
    }
  };

  return (
    <>
      <AppHeaderComponent
        context={context}
        addNew={() => (
          <Link to={"/leads/create"}>
            <AppButtonComponent
              text="Add New"
              btnIcon="add"
              stylename="primary"
              style={{
                background: context.theme.top_menu.button_background,
                color: context.theme.top_menu.button_font,
              }}
            />
          </Link>
        )}
        theme={context.theme}
        name="leads"
      />
      <ComponentTabs
        tabs={tabs}
        value={tabValue}
        setValue={setTabValue}
        handleChange={handleChange}
      />
      <div style={{ paddingTop: 200 }}>
        {tabValue <= 3 && (
          <>
            {data ? (
              <FilterTable columns={columns} data={data} options={options} />
            ) : (
              <Loading />
            )}
          </>
        )}

        {tabValue === 4 && <p>Settings</p>}
      </div>
    </>
  );
}
